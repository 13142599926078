/* eslint-disable react/prop-types */
import React, { createRef, useEffect } from "react";
import lottie from "lottie-web";

const Animation = (props) => {
  let animationContainer = createRef();
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: props.animation
    });
    return () => anim.destroy();
  }, []);

  return <div style={{ widht: "100px", height: "35vw"}} className="animation-container" ref={animationContainer}></div>;
};

export default Animation;

import React from "react"
import Layout from "../components/layout";
import Animation from "../components/common/Animation.jsx"

const PageNotFound = () => {
  return (
    <Layout>
      <div>
        <Animation animation={require("../assets/page-not-found.json")} />
      </div>
    </Layout>
  )
}

export default PageNotFound
